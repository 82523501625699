import React from 'react';
import { NoteType } from '../../types';
import { Trash } from 'lucide-react';

interface ScratchPadProps {
  addNewNote: (type: NoteType, content: string, isImportant?: boolean, name?: string, isFromScratchPad?: boolean) => void;
  noteTypes: NoteType[],
  note_loading: boolean,
  note_error: string | null,
  sp_content: string,
  sp_setContent: React.Dispatch<React.SetStateAction<string>>,
  sp_name: string,
  sp_setName: React.Dispatch<React.SetStateAction<string>>,
  resetScratchPad: () => void,
  addNewScratchPadNote: (type: NoteType | null) => void,
  sp_type: NoteType | null
}
const ScratchPad: React.FC<ScratchPadProps> = ({addNewNote, noteTypes, note_loading, note_error, sp_content, sp_setContent, sp_name, sp_setName, resetScratchPad, 
  addNewScratchPadNote, sp_type}) => {
  const defaultType = noteTypes.find((button: NoteType) => button.is_default);



  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    sp_setName(event.target.value);
  }

  const handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    sp_setContent(event.target.value);
  }

  const handleSaveClick = () => {
    addNewScratchPadNote(sp_type || defaultType || noteTypes[0]);
  }
  return (
    <div className="bg-gray-100 p-4 rounded-lg space-y-4">
      <textarea
        placeholder="Scratch Pad....."
        className="w-full h-60 p-2 rounded border border-gray-300"
        onChange={handleNoteChange}
        value={sp_content}
      />
      <div className="flex justify-between items-center">
        <input
          type="text"
          placeholder="Name of the note ....."
          className="p-2 rounded border border-gray-300"
          onChange={handleNameChange}
          value={sp_name}
        />
        <div className='flex gap-2 items-center mx-4'>
          <button onClick={resetScratchPad} ><Trash size={16}/></button>
          <button className="px-4 py-1 bg-white rounded-lg text-sm" disabled={note_loading} onClick={handleSaveClick}>Save as Note</button>
        </div>
      </div>
      {note_loading && <div>Loading...</div>}
      {note_error && <div>Error: {note_error}</div>}
    </div>
  );
};

export default ScratchPad;
// src/hooks/useTaskManager.ts
import { useState } from 'react';
import { Task } from '../types';
import { createNewTask, createNewSubtask } from '../utils/taskHelpers';

export const useTaskManager = (initialTasks: Task[]) => {
  const [tasks, setTasks] = useState<Task[]>(initialTasks);

  const addNewTask = () => {
    setTasks(prev => [...prev, createNewTask()]);
  };

  const addSubtask = (parentId: number) => {
    setTasks(prev => prev.map(task => {
      if (task.id === parentId) {
        return {
          ...task,
          subtasks: [...task.subtasks, createNewSubtask()]
        };
      }
      return task;
    }));
  };

  const updateTask = (taskId: number, field: keyof Task, value: any, parentId: number | null = null) => {
    setTasks(prev => prev.map(task => {
      if (parentId) {
        if (task.id === parentId) {
          return {
            ...task,
            subtasks: task.subtasks.map(subtask => 
              subtask.id === taskId ? { ...subtask, [field]: value } : subtask
            )
          };
        }
      } else if (task.id === taskId) {
        return { ...task, [field]: value };
      }
      return task;
    }));
  };

  const deleteTask = (taskId: number, parentId: number | null = null) => {
    setTasks(prev => prev.filter(task => task.id !== taskId));
  };

  const deleteSubtask = (taskId: number, parentId: any) => {
    console.log('Hello from MyComponent!');
    setTasks(prev => prev.map(task => {
      if (task.id === parentId) {
        return {
          ...task,
          subtasks: task.subtasks.filter(subtask => subtask.id !== taskId)
        };
      }
      return task;
    }));
  };

  return {
    tasks,
    addNewTask,
    addSubtask,
    updateTask,
    deleteTask,
    deleteSubtask
  };
};
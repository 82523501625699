import React, { useState } from 'react';
import { Plus, Trash } from 'lucide-react';
import {Task} from '../../types';


interface TaskInputProps {
  task: Task;
  isSubtask?: boolean;
  parentId?: number | null;
  onUpdate: (taskId: number, field: keyof Task, value: any, parentId?: number | null) => void;
  onAddSubtask: (taskId: number) => void;
  onDeleteTask: (taskId: number) => void;
  onDeleteSubtask: (taskId: number, parentId: any) => void;
}

const TaskInput: React.FC<TaskInputProps> = ({ 
  task, 
  isSubtask = false, 
  parentId = null, 
  onUpdate, 
  onAddSubtask ,
  onDeleteTask,
  onDeleteSubtask
}) => {
  const [newTag, setNewTag] = useState('');

  const handleTagAdd = () => {
    if (newTag.trim()) {
      onUpdate(
        task.id, 
        'tags', 
        [...(task.tags || []), newTag.trim()], 
        isSubtask ? parentId : null
      );
      setNewTag('');
    }
  };

  return (
    <div className={`p-3 rounded-lg ${task.highlightColor || 'bg-white'} ${isSubtask ? 'ml-8' : ''}`}>
      <div className="flex items-center gap-2">
        <input
          type="text"
          placeholder="Task title"
          value={task.title}
          onChange={(e) => onUpdate(task.id, 'title', e.target.value, isSubtask ? parentId : null)}
          className="flex-1 p-2 rounded border border-gray-200"
        />
        {!isSubtask && (
          <div>
          <button 
            onClick={() => onAddSubtask(task.id)}
            className="p-1 text-gray-500 hover:text-gray-700"
          >
            <Plus size={20} />
          </button>
          <button 
            onClick={() => onDeleteTask(task.id)}
            className="p-1 text-gray-500 hover:text-gray-700"
          >
            <Trash size={20} />
          </button>
          </div>
        )}
        {isSubtask && (
        <button 
          onClick={() => onDeleteSubtask(task.id, parentId)}
          className="p-1 text-gray-500 hover:text-gray-700"
        >
          <Trash size={20} />
        </button>
        )}
      </div>
      <div className="flex gap-2 mt-2">
        <input
          type="text"
          placeholder="Time"
          value={task.time}
          onChange={(e) => onUpdate(task.id, 'time', e.target.value, isSubtask ? parentId : null)}
          className="w-24 p-2 text-sm rounded border border-gray-200"
        />
        <input
          type="text"
          placeholder="Duration"
          value={task.duration}
          onChange={(e) => onUpdate(task.id, 'duration', e.target.value, isSubtask ? parentId : null)}
          className="w-24 p-2 text-sm rounded border border-gray-200"
        />
        <div className="flex gap-1 items-center">
          {task.tags?.map((tag, index) => (
            <span key={index} className="px-2 py-1 text-xs bg-gray-100 rounded-full">
              {tag}
            </span>
          ))}
          <input
            type="text"
            placeholder="Add tag"
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleTagAdd()}
            className="w-20 p-1 text-xs rounded border border-gray-200"
          />
        </div>
      </div>
    </div>
  );
};

export default TaskInput;
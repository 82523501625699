import { Tracker } from "../types";

export const createNewTracker = (): Tracker => ({
    id: Date.now(),
    name: '',
    color: '',
    completed: false
});

export const updateTracker = (tracker: Tracker, field: keyof Tracker, value: any) => {
    return { ...tracker, [field]: value };
};
import React from 'react';
import { ChevronDown } from 'lucide-react';
import TaskInput from './TaskInput';
import { Note , Task} from '../../types';

interface ProjectSectionProps {
  tasks: Task[];
  onAddTask: () => void;
  onUpdateTask: (taskId: number, field: keyof Task, value: any, parentId?: number | null) => void;
  onAddSubtask: (taskId: number) => void;
  onDeleteTask: (taskId: number) => void;
  onDeleteSubtask: (taskId: number, parentId: number) => void;
}

const ProjectSection: React.FC<ProjectSectionProps> = ({ 
  tasks, 
  onAddTask, 
  onUpdateTask, 
  onAddSubtask,
  onDeleteTask,
  onDeleteSubtask
}) => {
  return (
    <div className="bg-gray-100 p-4 rounded-lg space-y-4">
      <div className="flex gap-2">
        <button className="px-3 py-1 bg-white rounded-lg text-sm flex items-center gap-1">
          Projects <ChevronDown size={16} />
        </button>
        <button className="px-3 py-1 bg-white rounded-lg text-sm">Sort By</button>
        <button 
          className="px-3 py-1 bg-white rounded-lg text-sm"
          onClick={onAddTask}
        >
          New Task +
        </button>
      </div>

      <div className="space-y-2">
        {tasks.map(task => (
          <div key={task.id} className="space-y-2">
            <TaskInput 
              task={task} 
              onUpdate={onUpdateTask} 
              onAddSubtask={onAddSubtask}
              onDeleteTask={onDeleteTask}
              onDeleteSubtask={onDeleteSubtask}
            />
            {task.subtasks?.map(subtask => (
              <TaskInput
                key={subtask.id}
                task={subtask}
                isSubtask={true}
                parentId={task.id}
                onUpdate={onUpdateTask}
                onAddSubtask={onAddSubtask}
                onDeleteTask={onDeleteTask}
                onDeleteSubtask={onDeleteSubtask}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectSection;
import { useState, useEffect } from "react";
import { Tracker } from "../types";
import { createNewTracker } from "../utils/trackerHelpers";
import { apiClient } from "../utils/apiClient";

export const useTrackerManager = () => {
    const [trackers, setTrackers] = useState<Tracker[]>([]);
    const [trackers_loading, setLoading] = useState<boolean>(true);
    const [trackers_error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchTrackers = async () => {
          try {
            const data = await apiClient.get(`${process.env.REACT_APP_API_URL}/tracker/tracker-status`);;
            setTrackers(data);
          } catch (error: any) {
            setError(error.message);
          } finally {
            setLoading(false);
          }
        };
        fetchTrackers();
      }, []);

    const addNewTracker = () => {
        setTrackers(prev => [...prev, createNewTracker()]);
    };

    const updateTracker = async (trackerId: number, field: keyof Tracker, value: any) => {
        setLoading(true);
        if (field === 'completed') {
            try {
                const data = {
                    "tracker_id":trackerId,
                    "if_completed":value
                  }
                const res = await apiClient.post(`${process.env.REACT_APP_API_URL}/tracker/tracker-status`, data)
            }
            catch (error: any) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        }

        setTrackers(prev => prev.map(tracker => tracker.id === trackerId ? { ...tracker, [field]: value } : tracker));
        setLoading(false);
    };

    const deleteTracker = (trackerId: number) => {
        setTrackers(prev => prev.filter(tracker => tracker.id !== trackerId));
    };

    return { trackers, addNewTracker, updateTracker, deleteTracker, trackers_loading, trackers_error};
};
import React from 'react';
import { PlusCircle } from 'lucide-react';
import { Tracker } from '../../types';

interface TrackerComponentProps {
  trackers: Tracker[];
  onUpdate: (trackerId: number, field: keyof Tracker, value: any) => void;
  loading?: boolean;
  error?: string | null;
}

const TrackerComponent: React.FC<TrackerComponentProps> = ({ trackers, onUpdate, loading, error }) => {
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <span className="text-sm font-medium shrink-0">Trackers:</span>
        <div className="flex flex-wrap gap-2 overflow-hidden">
          {trackers.map((tracker: Tracker, index: number) => (
            tracker.completed ? (
              <button
                key={index}
                className="px-3 py-1 rounded-full bg-blue-100 text-sm flex items-center gap-1 max-w-full truncate"
                onClick={() => onUpdate(tracker.id, 'completed', !tracker.completed)}
              >
                <span className="truncate max-w-[150px]">{tracker.name}</span>
                <button className="w-4 h-4 bg-blue-200 rounded-full flex items-center justify-center shrink-0">
                  ✓
                </button>
              </button>
            ) : (
              <button
                key={index}
                className="px-3 py-1 rounded-full bg-blue-50 text-sm flex items-center gap-1 max-w-full truncate"
                onClick={() => onUpdate(tracker.id, 'completed', !tracker.completed)}
              >
                <span className="truncate max-w-[150px]">{tracker.name}</span>
              </button>
            )
          ))}
          <button className="text-gray-500 shrink-0">
            <PlusCircle size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TrackerComponent;
import React from 'react';
import { Note, NoteType } from '../../types';
import { Trash } from 'lucide-react';

interface QuickNoteProps {
  notes: Note[];
  buttons: NoteType[];
  addNewNote: (type: NoteType, content: string, isImportant?: boolean, name?: string, isFromScratchPad?: boolean) => void;
  note_types_loading: boolean;
  defaultType: NoteType;
  selectedType: NoteType;
  setSelectedType: (type: NoteType) => void;
}

const QuickNote: React.FC<QuickNoteProps> = ({ 
  notes, 
  buttons, 
  addNewNote, 
  note_types_loading, 
  defaultType, 
  selectedType, 
  setSelectedType 
}) => {
  const [content, setContent] = React.useState('');
  
  if (note_types_loading) {
    return <div>Loading...</div>;
  }

  const handleTypeChange = (type: NoteType) => {
    setSelectedType(type);
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContent(event.target.value);
  }

  const handleSaveClick = () => {
    addNewNote(selectedType, content);
    setSelectedType(defaultType);
    setContent('');
  }

  return (
    <div className="bg-gray-100 p-4 rounded-lg space-y-4">
      <div className="space-y-2">
        {notes.filter(note => note.isImportant).map((note: Note, index: number) => (
          <div 
            key={index} 
            className="px-3 py-1 bg-white rounded-lg text-sm break-words"
          >
            {note.type.name}: {note.content}
          </div>
        ))}
      </div>
      <div className="space-y-2">
        <input
          type="text"
          placeholder="Write here ..........."
          className="w-full p-2 rounded border border-gray-300"
          value={content}
          onChange={handleInputChange}
        />
        <div className="flex justify-between items-center">
          {selectedType.is_default ? (
            <div className="flex items-center gap-2 flex-wrap">
              {buttons
                .filter(button => !button.is_default)
                .map((button: NoteType, index: number) => (
                  <button 
                    key={index} 
                    className="px-3 py-1 bg-white rounded-lg text-sm" 
                    onClick={() => handleTypeChange(button)}
                  >
                    {button.name} +
                  </button>
                ))}
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <button 
                className="px-3 py-1 bg-orange-600 text-white rounded-lg text-sm flex items-center gap-1" 
                onClick={() => handleTypeChange(defaultType)}
              > 
                {selectedType.name} 
                <Trash size={14} />
              </button>
            </div>
          )}
          <button 
            className="px-4 py-1 bg-green-600 text-white rounded-lg text-sm"
            onClick={handleSaveClick}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuickNote;
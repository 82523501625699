// src/utils/taskHelpers.ts
import { Task } from '../types';

export const createNewTask = (): Task => ({
  id: Date.now(),
  title: '',
  time: '',
  duration: '',
  tags: [],
  isNew: true,
  subtasks: []
});

export const createNewSubtask = (): Task => ({
  id: Date.now(),
  title: '',
  time: '',
  duration: '',
  tags: [],
  isNew: true,
  subtasks: []
});


import React from 'react';
import TrackerComponent from './TrackerComponent';
import ScratchPad from './ScratchPad';
import ProjectSection from './ProjectSection';
import NoteCard from './NoteCard';
import Calendar from './Calendar';
import QuickNote from './QuickNote';

import { useTaskManager } from '../../hooks/useTaskManager';
import { useNoteManager } from '../../hooks/useNoteManager';
import { useTrackerManager } from '../../hooks/useTrackerManager';
import { useNoteTypeManager } from '../../hooks/useNoteTypeManager';

const DailyPlanner: React.FC = () => {
  const {trackers, updateTracker, trackers_loading, trackers_error} = useTrackerManager();
  const {tasks, addNewTask, addSubtask, updateTask, deleteTask, deleteSubtask} = useTaskManager([]);
  const {notes, addNewNote, updateNote, note_loading, note_error, sp_content, sp_name, sp_setContent, sp_setName, resetScratchPad, addNewScratchPadNote,
    fillExistingNote, sp_type
  } = useNoteManager();
  const {noteTypes, defaultType, selectedType, setSelectedType, note_types_loading} = useNoteTypeManager();

  return (
    <div className="max-w-[1400px] mx-auto p-4">
      <h1 className="text-2xl font-semibold text-center mb-6">Daily Planner</h1>

      {/* Mobile: Single Column */}
      <div className="md:hidden grid grid-cols-1 gap-6">
        <TrackerComponent 
          trackers={trackers} 
          onUpdate={updateTracker} 
          loading={trackers_loading} 
          error={trackers_error}
        />
        
        <QuickNote 
          notes={notes}
          buttons={noteTypes}
          addNewNote={addNewNote}
          note_types_loading={note_types_loading}
          defaultType={defaultType}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
        />

        <div className="grid grid-cols-1 gap-1 max-h-[400px] overflow-y-auto space-y-1">
          {notes.map((note, index) => (
            <NoteCard key={index} note={note} onUpdate={updateNote} fillExistingNote={fillExistingNote}/>
          ))}
        </div>

        <ScratchPad
          addNewNote={addNewNote}
          noteTypes={noteTypes}
          note_loading={note_loading}
          note_error={note_error}
          sp_content={sp_content}
          sp_name={sp_name}
          sp_setContent={sp_setContent}
          sp_setName={sp_setName}
          resetScratchPad={resetScratchPad}
          addNewScratchPadNote={addNewScratchPadNote}
          sp_type={sp_type}
        />
        
        <ProjectSection
          tasks={tasks}
          onAddTask={addNewTask}
          onUpdateTask={updateTask}
          onAddSubtask={addSubtask}
          onDeleteTask={deleteTask}
          onDeleteSubtask={deleteSubtask}
        />

        <div className="bg-gray-100 p-4 rounded-lg space-y-4">
          <h2 className="font-medium">Note For Tomorrow's Me</h2>
          <textarea
            placeholder="Write here ..........."
            className="w-full h-32 p-2 rounded border border-gray-300"
          />
          <button className="px-4 py-1 bg-green-600 text-white rounded-lg text-sm">Save</button>
        </div>

        <QuickNote 
          notes={[]}
          buttons={noteTypes}
          addNewNote={addNewNote}
          note_types_loading={note_types_loading}
          defaultType={defaultType}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
        />

        <Calendar />
      </div>

      {/* Desktop: Two Columns */}
      <div className="hidden md:flex gap-6">
        <div className="flex-1 space-y-6">
          <TrackerComponent 
            trackers={trackers} 
            onUpdate={updateTracker} 
            loading={trackers_loading} 
            error={trackers_error}
          />
          
          <QuickNote 
            notes={notes}
            buttons={noteTypes}
            addNewNote={addNewNote}
            note_types_loading={note_types_loading}
            defaultType={defaultType}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
          />
          
          <ScratchPad
            addNewNote={addNewNote}
            noteTypes={noteTypes}
            note_loading={note_loading}
            note_error={note_error}
            sp_content={sp_content}
            sp_name={sp_name}
            sp_setContent={sp_setContent}
            sp_setName={sp_setName}
            resetScratchPad={resetScratchPad}
            addNewScratchPadNote={addNewScratchPadNote}
            sp_type={sp_type}
          />
          
          <ProjectSection
            tasks={tasks}
            onAddTask={addNewTask}
            onUpdateTask={updateTask}
            onAddSubtask={addSubtask}
            onDeleteTask={deleteTask}
            onDeleteSubtask={deleteSubtask}
          />

          <div className="bg-gray-100 p-4 rounded-lg space-y-4">
            <h2 className="font-medium">Note For Tomorrow's Me</h2>
            <textarea
              placeholder="Write here ..........."
              className="w-full h-32 p-2 rounded border border-gray-300"
            />
            <button className="px-4 py-1 bg-green-600 text-white rounded-lg text-sm">Save</button>
          </div>

          <QuickNote 
            notes={[]}
            buttons={noteTypes}
            addNewNote={addNewNote}
            note_types_loading={note_types_loading}
            defaultType={defaultType}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
          />
        </div>

        <div className="w-[400px] space-y-6">
          <div className="grid grid-cols-1 gap-1 h-[400px] overflow-y-auto space-y-1">
            {notes.map((note, index) => (
              <NoteCard key={index} note={note} onUpdate={updateNote} fillExistingNote={fillExistingNote}/>
            ))}
          </div>
          <Calendar />
        </div>
      </div>
    </div>
  );
};

export default DailyPlanner;
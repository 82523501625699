import { Note, NoteType } from '../types';


export const createNewNote = (type: NoteType, content: string, isImportant?: boolean, name?: string, isFromScratchPad?: boolean, id?: number): Note => ({
  id: id || Date.now(),
  type: type,
  time: new Date().toLocaleString(),
  isImportant: isImportant,
  content: content,
  name: name, 
  isFromScratchPad: isFromScratchPad
});




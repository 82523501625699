// src/hooks/useTaskManager.ts
import { useState, useEffect } from 'react';
import { Note, NoteType } from '../types';
import { createNewNote } from '../utils/noteHelpers';
import { apiClient } from '../utils/apiClient';

export const useNoteManager = () => {
    const [notes, setNotes] = useState<Note[]>([]);
    const [note_loading, setLoading] = useState<boolean>(true);
    const [note_error, setError] = useState<string | null>(null);
    const [sp_content, sp_setContent] = useState('');
    const [sp_name, sp_setName] = useState('');
    const [sp_type, sp_setType] = useState<NoteType | null>(null);
    const [sp_id, sp_setId] = useState<number | null>(null);

    const transformNote = (apiNote: any): Note => ({
        id: apiNote.id,
        type: {
          id: apiNote.note_type.id,
          name: apiNote.note_type.name,
          is_default: apiNote.note_type.is_default,
          is_always_important: apiNote.note_type.is_always_important
        },
        time: apiNote.created_at,
        content: apiNote.content,
        isImportant: apiNote.is_important,
        name: apiNote.name || undefined,
        isFromScratchPad: apiNote.is_from_scratchpad
      });

    useEffect(() => {
        const fetchNotes = async () => {
          try {
            const data = await apiClient.get(`${process.env.REACT_APP_API_URL}/note/day-notes`);;
            setNotes(data.map(transformNote));
          } catch (error: any) {
            setError(error.message);
          } finally {
            setLoading(false);
          }
        };
        fetchNotes();
      }, []);

    const addNewNote = async(type: NoteType | null, content: string, isImportant?: boolean, name?: string, isFromScratchPad?: boolean) => {
        if (!type) {
            throw new Error('Note type is required');
        }
        try {
            setLoading(true);
            const data = {
                note_type_id: type.id,
                content: content,
                // is_important: isImportant,
                name: name,
                is_from_scratchpad: isFromScratchPad
            }
            const note: any = await apiClient.post(`${process.env.REACT_APP_API_URL}/note/create-note`, data);
            setNotes(prev => [...prev, createNewNote(type, content, isImportant, name, isFromScratchPad, note.id)]);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const addNewScratchPadNote = async (type: NoteType | null) => {
        if (!type) {
            throw new Error('Note type is required');
        }
        try {
            setLoading(true);
            console.log('SP ID: ', sp_id);
            if (sp_id) {
                apiClient.put(`${process.env.REACT_APP_API_URL}/note/create-note/${sp_id}`, {
                    note_type_id: type.id,
                    content: sp_content,
                    // is_important: isImportant,
                    name: sp_name,
                    is_from_scratchpad: true,
                });
                setNotes(prev => prev.map(note => note.id === sp_id ? { ...note, type: type, content: sp_content, isImportant: false, name: sp_name, isFromScratchPad: true } : note));
                return;
            }
            else {
                const data = {
                    note_type_id: type.id,
                    content: sp_content,
                    // is_important: isImportant,
                    name: sp_name,
                    is_from_scratchpad: true
                }
                const note: any = await apiClient.post(`${process.env.REACT_APP_API_URL}/note/create-note`, data);
                sp_setId(note.id);
                console.log('SP ID Setting: ', note.id);
                setNotes(prev => [...prev, createNewNote(type, sp_content, false, sp_name, true, note.id)]);
            }
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }

    const resetScratchPad = () => {
        console.log('Hello from reset!');
        sp_setContent('');
        sp_setName('');
        sp_setType(null);
        sp_setId(null);
    }

    const fillExistingNote = (noteId: number) => {
        const note = notes.find(note => note.id === noteId);
        if (note) {
            sp_setContent(note.content);
            sp_setName(note.name || '');
            sp_setType(note.type);
            sp_setId(note.id);
        }
    }

    const updateNote = (noteId: number, field: keyof Note, value: any) => {
        try {
            setLoading(true);
            let api_field: any = field;
            if (field === 'isImportant') {
                api_field = 'is_important';
            }
            const data = {
                [api_field]: value
            }
            apiClient.put(`${process.env.REACT_APP_API_URL}/note/create-note/${noteId}`, data);
            setNotes(prev => prev.map(note => note.id === noteId ? { ...note, [field]: value } : note));
        } catch (error: any) {
            console.log(error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const deleteNote = (noteId: number) => {
        setNotes(prev => prev.filter(note => note.id !== noteId));
    };

    return { notes, addNewNote, updateNote, deleteNote, note_loading, note_error, sp_content, sp_setContent, sp_name, sp_setName, addNewScratchPadNote, resetScratchPad, fillExistingNote, sp_type };
};
import {useEffect, useState} from 'react';
import { apiClient } from '../utils/apiClient';
import { Note, NoteType } from '../types';

export const useNoteTypeManager = () => {
    const [noteTypes, setNoteTypes] = useState<NoteType[]>([]);
    const [note_types_loading, setLoading] = useState<boolean>(true);
    const [note_types_error, setError] = useState<string | null>(null);    
    const [defaultType, setDefaultType] = useState<NoteType>({
        id: -1,
        name: 'Loading...',
        is_default: false
      });

    const [selectedType, setSelectedType] = useState<NoteType>({
        id: -1,
        name: 'Loading...',
        is_default: false
      });

    const handleTypeChange = (type: NoteType) => {
        setDefaultType(type);
    }

    useEffect(() => {
        const fetchNoteTypes = async () => {
          try {
            const data = await apiClient.get(`${process.env.REACT_APP_API_URL}/note/note-types`);;
            setDefaultType(data.find((noteType: NoteType) => noteType.is_default));
            setSelectedType(data.find((noteType: NoteType) => noteType.is_default));
            setNoteTypes(data);
          } catch (error: any) {
            setError(error.message);
          } finally {
            setLoading(false);
          }
        };
        fetchNoteTypes();
      }, []);

      return {
        noteTypes,
        defaultType,
        selectedType,
        setSelectedType,
        note_types_loading,
        note_types_error
      }

    };